export const appBuildAssetsDir = "/fts-app/"

export const appRootId = "__ftsapp"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Unlock unparalleled access to the global market with The Efficient Freight Company. Partnered with Fast Track Shipping, we offer top-tier customs clearance, sea and air shipment, and international insurance services for seamless import and export operations. Our strategic presence at major ports and airports ensures efficient handling of shipments worldwide. Utilizing cutting-edge technology and a vast network of partners, we guarantee optimal logistics management and international product distribution. Experience excellence in freight forwarding with The Efficient Freight Company and Fast Track Shipping."},{"name":"author","content":"World Shipping Alliance"},{"name":"application-name","content":"Fast Track Shipping"},{"name":"keywords","content":"Freight Company, Efficient Freight Company, Customs Clearance, Sea Shipment, Air Shipment, International Insurance, Fast Track Shipping, Freight Market, Logistics Management, Global Market Access, Import and Export, Product Distribution, Strategic Gateway, Freight Forwarding Services"}],"link":[],"style":[],"script":[],"noscript":[],"title":"Fast Track Shipping","charset":"utf-8","viewport":"width=device-width, initial-scale=1","htmlAttrs":{"lang":"en","dir":"ltr"}}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const appRootAttrs = {"id":"__ftsapp"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__ftsapp'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"