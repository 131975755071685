import { default as aboutl48PoBfZ4iMeta } from "/www/wwwroot/fts_nuxt/pages/about.vue?macro=true";
import { default as indexQmwaYyvDQFMeta } from "/www/wwwroot/fts_nuxt/pages/dashboard/index.vue?macro=true";
import { default as messagesftLw9KP1CuMeta } from "/www/wwwroot/fts_nuxt/pages/dashboard/messages.vue?macro=true";
import { default as settingsTGYL4TG3ztMeta } from "/www/wwwroot/fts_nuxt/pages/dashboard/settings.vue?macro=true";
import { default as users0tXmXtgT9KMeta } from "/www/wwwroot/fts_nuxt/pages/dashboard/users.vue?macro=true";
import { default as index3H4WRmoWRCMeta } from "/www/wwwroot/fts_nuxt/pages/dashboard/website-data/index.vue?macro=true";
import { default as mediamqn3vvq0bGMeta } from "/www/wwwroot/fts_nuxt/pages/dashboard/website-data/media.vue?macro=true";
import { default as _91id_93znvxNA2j9qMeta } from "/www/wwwroot/fts_nuxt/pages/dashboard/website-data/pages/[id].vue?macro=true";
import { default as indexITvBWzIum7Meta } from "/www/wwwroot/fts_nuxt/pages/dashboard/website-data/pages/index.vue?macro=true";
import { default as servicesHLnFWXYV9wMeta } from "/www/wwwroot/fts_nuxt/pages/dashboard/website-data/services.vue?macro=true";
import { default as teamzr6IJiXAfuMeta } from "/www/wwwroot/fts_nuxt/pages/dashboard/website-data/team.vue?macro=true";
import { default as indexw98RruX8DYMeta } from "/www/wwwroot/fts_nuxt/pages/index.vue?macro=true";
import { default as login7E72DxBW1pMeta } from "/www/wwwroot/fts_nuxt/pages/login.vue?macro=true";
import { default as indexWR7vKWsglzMeta } from "/www/wwwroot/fts_nuxt/pages/services/index.vue?macro=true";
import { default as why_45ftsP45yEBbn3PMeta } from "/www/wwwroot/fts_nuxt/pages/why-fts.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/www/wwwroot/fts_nuxt/pages/about.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexQmwaYyvDQFMeta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-messages",
    path: "/dashboard/messages",
    meta: messagesftLw9KP1CuMeta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/dashboard/messages.vue")
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: settingsTGYL4TG3ztMeta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/dashboard/settings.vue")
  },
  {
    name: "dashboard-users",
    path: "/dashboard/users",
    meta: users0tXmXtgT9KMeta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/dashboard/users.vue")
  },
  {
    name: "dashboard-website-data",
    path: "/dashboard/website-data",
    meta: index3H4WRmoWRCMeta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/dashboard/website-data/index.vue")
  },
  {
    name: "dashboard-website-data-media",
    path: "/dashboard/website-data/media",
    meta: mediamqn3vvq0bGMeta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/dashboard/website-data/media.vue")
  },
  {
    name: "dashboard-website-data-pages-id",
    path: "/dashboard/website-data/pages/:id()",
    meta: _91id_93znvxNA2j9qMeta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/dashboard/website-data/pages/[id].vue")
  },
  {
    name: "dashboard-website-data-pages",
    path: "/dashboard/website-data/pages",
    meta: indexITvBWzIum7Meta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/dashboard/website-data/pages/index.vue")
  },
  {
    name: "dashboard-website-data-services",
    path: "/dashboard/website-data/services",
    meta: servicesHLnFWXYV9wMeta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/dashboard/website-data/services.vue")
  },
  {
    name: "dashboard-website-data-team",
    path: "/dashboard/website-data/team",
    meta: teamzr6IJiXAfuMeta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/dashboard/website-data/team.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/www/wwwroot/fts_nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login7E72DxBW1pMeta || {},
    component: () => import("/www/wwwroot/fts_nuxt/pages/login.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/www/wwwroot/fts_nuxt/pages/services/index.vue")
  },
  {
    name: "why-fts",
    path: "/why-fts",
    component: () => import("/www/wwwroot/fts_nuxt/pages/why-fts.vue")
  }
]