import revive_payload_client_4sVQNw7RlN from "/www/wwwroot/fts_nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/www/wwwroot/fts_nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/www/wwwroot/fts_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/www/wwwroot/fts_nuxt/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/www/wwwroot/fts_nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/www/wwwroot/fts_nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/www/wwwroot/fts_nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/www/wwwroot/fts_nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/www/wwwroot/fts_nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/www/wwwroot/fts_nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/www/wwwroot/fts_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_42RIGIx6e8 from "/www/wwwroot/fts_nuxt/node_modules/@nuxt/image/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/www/wwwroot/fts_nuxt/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import settings_ZJy0LLHwcO from "/www/wwwroot/fts_nuxt/plugins/settings.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_42RIGIx6e8,
  plugin_client_OVoKJro5pc,
  settings_ZJy0LLHwcO
]